<script>
  export default {
    props: {
      title: {
        type: String,
        default: '',
      },
      body: {
        type: String,
        default: ''
      }
    },
    data: () => ({
      showDialog: false
    })
  }

  // class="md-simple md-success md-lg"
</script>

<template>
  <div>
  </div>
</template>

<style lang="scss" module>
  @import '@design';

  .button {
    cursor: pointer;
  }

  .dialog {
    max-width: 600px;
    padding-left: 20px;
    padding-right: 20px;
  }


</style>
