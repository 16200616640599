<script>
  export default {
    components: {},
    props: {
      src: {
        type: [String, Object],
        default: ''
      },
      alt: {
        type: [String],
        default: ''
      }
    },
    data() {
      return {
      }
    },
    computed: {
      srcDisplay() {
        if(this.src && typeof this.src==='object') {
          if(!this.$vuetify || !this.$vuetify.theme) {
            console.error('$vuetify.theme Não encontrado ao exibir imagem');
            return this.src;
          }
          if(this.$vuetify.theme.dark && this.src.dark) {
            return this.src.dark;
          }
          if(!this.$vuetify.theme.dark && this.src.light) {
            return this.src.light;
          }
          if(this.src.url) {
            return this.src.url;
          }
        }
        if(this.src) {
          return this.src.light || this.src.dark || this.src.url || this.src;
        }
          return '';
      }
    },
  }
</script>
<template>
  <img :src="srcDisplay" :alt="alt" v-bind="$attrs" v-on="$listeners" />
</template>


/*
  img: {
    dark: 'http://algodfddsf',
    light: 'http://algodfddsf',
    url: 'http://algodfddsf',
  }




*/
















