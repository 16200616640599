<script>

export default {
  page: {
    title: 'Loading page...',
    meta: [{ name: 'description', content: 'Loading page...' }],
  },
  components: {},
}
</script>

<template>
    <Transition appear>
      <VIcon :class="$style.loadingIcon" name="sync" spin />
      <VProgressCircular
          :indeterminate="false"
          :rotate="true"
          :size="32"
          value=""
          :width="4"
          color="light-blue"
      ></VProgressCircular>
    </Transition>
</template>

<style lang="scss" module>
// @import '@design';

.loadingIcon {
  display: block;
  margin: 0 auto;

  // stylelint-disable-next-line selector-class-pattern
  &:global(.v-enter-active) {
    transition: opacity 1s;
  }
  // stylelint-disable-next-line selector-class-pattern
  &:global(.v-enter) {
    opacity: 0;
  }
}
</style>
