

export default {
  routeLogged: {
    ADMIN: 'admin/dashboard',
    CLIENT: 'client/dashboard',
    COMPANY: 'company/dashboard',
  },
  routeNotLogged: {
    ADMIN: 'admin/login',
    CLIENT: 'client/login',
    COMPANY: 'company/login',
  },
  routeLogOut: {
    ADMIN: 'admin/login',
    CLIENT: 'client/login',
    COMPANY: 'company/login',
  },
  companySelect: {
    ADMIN: 'admin/select_company',
    CLIENT: 'client/select_company',
    COMPANY: 'company/select_company',
  },
}
