const _ = require('lodash');
let toExport = {};


// Baseado no Vuetify
toExport.getNestedValue = function(obj, path, fallback) {
  const last = path.length - 1

  if (last < 0) return obj === undefined ? fallback : obj

  for (let i = 0; i < last; i++) {
    if (obj == null) {
      return fallback
    }
    obj = obj[path[i]]
  }

  if (obj == null) return fallback

  return obj[path[last]] === undefined ? fallback : obj[path[last]]
}

// Baseado no Vuetify
toExport.getObjectValueByPath = function(obj, path, fallback, getter=null) {
  // credit: http://stackoverflow.com/questions/6491463/accessing-nested-javascript-objects-with-string-key#comment55278413_6491621
  if (obj == null || !path || typeof path !== 'string') return fallback
  if (obj[path] !== undefined) {
    if(typeof getter ==='function') {
      return getter(obj[path])
    }
    return obj[path]
  }
  path = path.replace(/\[(\w+)\]/g, '.$1') // convert indexes to properties
  path = path.replace(/^\./, '') // strip a leading dot

  if(typeof getter ==='function') {
    return getter(toExport.getNestedValue(obj, path.split('.'), fallback))
  }
  return toExport.getNestedValue(obj, path.split('.'), fallback);
}


function planificar(base, local, currentPath='', maxDeep = 5) {
  if (maxDeep<=0) {
    return;
  }
  if (typeof local === 'object' && _.isEmpty(local) && currentPath) {
    delete base[currentPath];
    return;
  }
  if (typeof local === 'object' && !local['.sv']) {
    for (const key in local) {
      const result = planificar(base, local[key], (currentPath?currentPath+'/':'')+key, maxDeep-1);
      if (result) {
        base[currentPath+'/'+key] = result;
      }
    }
    delete base[currentPath];
  }
  else {
    return local;
  }
}

// let obj = {
//   'a/b': {
//     r: 3,
//     l: 4,
//     k: {
//       ['.sv']: {value:5}
//     }
//   },
//   'k/l': {
//     d: {
//       y: 6
//     }
//   },
//   'empty': {}
// };
// planificar(obj, obj);
// console.log(obj);




let obj2 = {
  'a/b': {
    r: 3,
    l: 4,
    k: {
      ['.sv']: {value:5}
    }
  },
  'k/l': {
    d: {
      y: 6
    }
  },
  'empty': {}
};


toExport.planificar = planificar;


toExport.mergeSumObj = function(objs, maxDeep=8, ret= {}) {
  if(maxDeep<=0) {
    console.warn('mergeSumObj atingiu o número máximo de profundidade');
    return;
  }
  const keys = {};
  for (const obj of objs) {
    for (const j in obj) {
      keys[j] = true;
    }
  }
  for (const i in keys) {
    const objItems = [];
    for (const obj of objs) {
      if(typeof obj?.[i] ==='undefined') {
        continue;
      }
      if(typeof obj[i] === 'object') {
        objItems.push(obj[i]);
      }
      else if(typeof obj[i] === 'number') {
        ret[i] = (ret[i]||0) + obj[i];
      }
    }
    if(objItems.length) {
      ret[i] = {};
      toExport.mergeSumObj(objItems, maxDeep-1, ret[i]);
    }
  }
  return ret;
};



module.exports = toExport;
