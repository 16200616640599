import "@common/ExtensionsJS";
import Vue from "vue";
import '@src/db.js' // Precisa ser antes do import do store
import "@src/plugins/axios";
import App from "@src/App.vue";
import router from "@src/router";
import store from "@src/store";
import vuetify from "./plugins/vuetify";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import '@fortawesome/fontawesome-free/css/all.css'
import "@mdi/font/css/materialdesignicons.css";
import i18n from "@src/plugins/i18n";
import pluginGlobal from '@src/plugins/global.js';
import '@src/plugins/vuefire';
import VueTheMask from 'vue-the-mask';
import '@components/_globals';
import money from 'v-money';
import GmapVue from 'gmap-vue';
import {config} from '@src/config';
// import * as Chart  from "vue-google-charts/legacy";
const _ = require('lodash');

const fcmI18n = require('@i18n')

import moment from 'moment'

Vue.use(pluginGlobal);
Vue.use(VueTheMask);
Vue.use(money);
// Vue.use(Chart);

import VueSweetalert2 from 'vue-sweetalert2';

// If you don't need the styles, do not connect
// import 'sweetalert2/dist/sweetalert2.min.css';

import '@src/design/sweetalert2Theme.scss';

import 'nprogress/nprogress.css';

Vue.use(VueSweetalert2);

Vue.use(GmapVue, {
  load: {
    key: config.maps.key,
    libraries: 'places',
  },
  installComponents: true
});

Vue.config.productionTip = false;

require('moment/locale/pt-br');
window.moment = moment;
window._ = _;


if(process.env.NODE_ENV !== 'production') {
  window.firebaseGenerator = require('@common/firebaseGenerator');
  window.StringUtils = require('@common/StringUtils');
  window.FcmModel = require('@common/model/FcmModel');
  window.qs = require('qs');
  window.path = require('path-browserify');
  const  { nanoid }  =  require ( 'nanoid/non-secure' )
  window.nanoid = nanoid;
}

// Reseta os Services workers se houver algum problema com a atualização dos mesmos
if(window.navigator && navigator.serviceWorker) {
  navigator.serviceWorker.getRegistrations()
    .then(function(registrations) {
      for(let registration of registrations) {
        registration?.unregister?.();
      }
    });
}


export const app = new Vue({
  router,
  store,
  vuetify,
  i18n,
  beforeMount() {
    const locale = this.$i18n.locale;
    if(fcmI18n.languages[locale]) {
      this.$vuetify.lang.current = fcmI18n.languages[locale].vuetify;
    }
  },
  render: h => h(App)
}).$mount("#app");

if(window) {
  window.vueApp = app;
}

if (module.hot) {
  module.hot.accept(['@i18n/en-US', '@i18n/pt-BR'], function () {
    i18n.setLocaleMessage('en-US', require('@i18n/en-US').default)
    i18n.setLocaleMessage('pt-BR', require('@i18n/pt-BR').default)
    // Or the following hot updates via $i18n property
    // app.$i18n.setLocaleMessage('en', require('./en').default)
    // app.$i18n.setLocaleMessage('ja', require('./ja').default)
  })
}





/*

router.beforeEach((to, from, next) => {
  let currentUser = auth().currentUser
  let requiresAuth = to.matched.some(record => record.meta.requiresAuth)

  if (requiresAuth && !currentUser) {
    next('Login')
  } else if (!requiresAuth && currentUser) {
    next()
  } else {
    next()
  }
})

let app

// Initialize the app after Firebase has iniitalized
auth().onAuthStateChanged(function (user) {
  if (!app) {
    app = new Vue({
      el: '#app',
      router,
      render: h => h(App)
    })
  }
})

*/
