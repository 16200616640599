<script src="../../old_voxtomweb/appgvox.js"></script>
<template>
  <div id="app-root">
    <RouterView></RouterView>
  </div>
</template>

<script>
import 'vuetify/dist/vuetify.min.css';
import '@src/design/ck-editor-content-styles.css';
import {appConfig} from '@src/config';
const fcmI18n = require('@i18n/index');

let {languages} = fcmI18n;

export default {
  name: "App",

  components: {},

  data: () => ({
    //
  }),
  page() {
    return {
      titleTemplate(title) {
        title = typeof title === 'function' ? title(this.$store) : title;
        return title ? `${title} | ${appConfig.title}` : appConfig.title;
      },
      meta: this.metaComputed,
      link: this.linkComputed,
    }
  },
  computed:{
    metaComputed() {
      let metaRet = [];
      metaRet.push({property: 'og:locale', content: this.$i18n.locale});
      for(let language in languages) {
        metaRet.push({property: 'og:locale:alternate', content: language});
      }
      return metaRet;
    },
    linkComputed() {
      let metaRet = [];
      if(this.$router.currentRoute) {
        metaRet.push({ rel: 'canonical', href: appConfig.baseUrl+'/'+this.$router.currentRoute.fullPath });
        metaRet.push({ rel: 'og:url', href: appConfig.baseUrl+'/'+this.$router.currentRoute.fullPath });
        for(let language in languages) {
          metaRet.push({ rel: 'alternate', href: appConfig.baseUrl+'/'+this.$router.currentRoute.fullPath+'?locale='+language, hreflang: language });
        }
      }
      return metaRet;
    }
  },
};
</script>


<style lang="scss">
@import '@design';
// @import '@src/design/sweetalert2Theme.scss';
// Allow element/type selectors, because this is global CSS.
// stylelint-disable selector-max-type, selector-class-pattern

// Normalize default styles across browsers,
// https://necolas.github.io/normalize.css/
//  @import '~normalize.css/normalize.css';



// Design variables and utilities from src/design.
// @import '@design';
// @import '~typeface-montserrat/index.css';

// .v-parallax__content {
//   padding: 50px 32px;
// }


.v-application--wrap {
  max-width: none;
}

html {
  overflow-y: auto;
  background-color: #000000;
 // background-color: var(--v-navBarBg-base);
}

body {
  background-color: #000000;
//  background-color: var(--v-navBarBg-base);
}

* {
  scrollbar-width: thin;
  scrollbar-color: var(--v-scroll-base, #5a5a5a) var(--v-scrollBg-base, #121212);
}

/* Works on Chrome/Edge/Safari */
 *::-webkit-scrollbar {
   width: 7px;
 }
*::-webkit-scrollbar-track {
  background: var(--v-scrollBg-base, #121212);
}
*::-webkit-scrollbar-thumb {
  background-color: var(--v-scroll-base, #5a5a5a);
  border-radius: 20px;
 // border: 3px solid #121212;
}

/*
* {
  scrollbar-width: thin;
  scrollbar-color: blue orange;
}

!* Works on Chrome/Edge/Safari *!
*::-webkit-scrollbar {
  width: 12px;
}
*::-webkit-scrollbar-track {
  background: orange;
}
*::-webkit-scrollbar-thumb {
  background-color: blue;
  border-radius: 20px;
  border: 3px solid orange;
} */

// flex-box

// global font styles - member apply dark/white mode

.overline {
  color: $voxtom_green;
  font-size: 12px !important;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  text-transform: uppercase;
  margin: 0 0 8px 0;

  @include for-phone-only {
    text-align: center !important;
  }
}

.big-title {
  // margin: 24px 0 0 0;
  font-size: 41px !important;
  letter-spacing: 0.011em;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.08349;
  letter-spacing: -.003em;
  color: $color-body-white;
  text-align: left;

  @include for-phone-only {
    text-align: center !important;
  }
}

.subtitle {
  font-size: 18px !important;
  letter-spacing: 0.011em;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.08349;
  letter-spacing: -.003em;
  color: $color-body-white;
  text-align: left;

  @include for-phone-only {
    text-align: center !important;
  }
}


.FcmColorContent {
  align-items: flex-end;
  display: flex;
}

.txt-body {
  margin: 24px 0 0 0;
  font-size: 21px;
  line-height: 1.19048;
  letter-spacing: .011em;
  font-weight: 500;
  color: $color-body-gray;

  @include for-phone-only {
    text-align: center;
    font-weight: 600;
  }
}

.row-nowrap {
  display: flex;
  flex-flow: row nowrap;
}

.col-nowrap {
  display: flex;
  flex-flow: column nowrap;
}

.jwSearchTextField {
  .v-input__control {
    .v-input__slot {
      border-radius: 10px;
      padding: 2px 15px 2px 10px;
      background-color: var(--v-listItemBg-base);
      color: var(--v-listItemTextSecondary-base);
      i {
        color: var(--v-listItemTextSecondary-base)!important;
      }
      fieldset {
        border: none;
      }
    }
    .v-input__slot:before, .v-input__slot:after {
      border: none!important;
    }
  }
  .v-data-table-header-mobile__wrapper {
    display: none;
  }
}

.jwDataTable {
  background-color: var(--v-listItemBg-base)!important;
  overflow: auto;
  border-radius: 10px;
  td, th {
    padding: 18px 10px 18px 23px;
    font-size: 15px;
  }
  tr {
    cursor: pointer;
  }
}

.jwDataTable.v-data-table >.v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: inherit;
}


.jwDataTableItemSelected {
  background-color: var(--v-listItemSelectedBg-base)!important;
}

.jwCursorPointer {
  cursor: pointer;
}

.jwImgContent {
  display: flex;
  align-content: center;
  justify-items: center;
  padding: 10px;
  margin: 2px;

  background-color: var(--v-formBodyBg-base);
  border-radius: 6px;

  .imgIcon {
    color: var(--v-buttonBg-base);
    font-size: 35px;
  }
  .imgText {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-items: center;
    text-align: center;
    margin-left: 8px;
    .imgName {

    }
    .imgAddOrCheck {
      color: var(--v-buttonBg-base);
    }
  }
}

.removeInputSpin {
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
}


.backgroundDefault {
  background: -moz-linear-gradient(45deg, rgba(0, 160, 92, 0.4) 0%, rgba(0, 160, 94, 0.4) 1%, rgba(0, 140, 191, 0.4) 60%, rgba(0, 140, 191, 0.4) 100%);
  background: -webkit-linear-gradient(
          45deg, rgba(0, 160, 92, 0.4) 0%, rgba(0, 160, 94, 0.4) 1%, rgba(0, 140, 191, 0.4) 60%, rgba(0, 140, 191, 0.4) 100%);
  background: url('@assets/images/all/soft_lights_fullbg.png'), linear-gradient(
          45deg, rgba(0, 160, 92, 0.4) 0%, rgba(0, 160, 94, 0.4) 1%, rgba(0, 140, 191, 0.4) 60%, rgba(0, 140, 191, 0.4) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#6600a05c', endColorstr='#66008cbf', GradientType=1);
  background-repeat: no-repeat;
  background-size: cover;
}

</style>
