"use strict";

// Object.defineProperty(exports, "__esModule", { value: true });
let toExport = {};


toExport.rgba2css = function(rgba) {
  if(!rgba) {
    return 'rgba(255, 255, 255, 1)'
  }
  return 'rgba('+(rgba.r|| 0)+', '+(rgba.g|| 0)+', '+(rgba.b|| 0)+', '+(rgba.a|| 1)+')';
};

module.exports = toExport;
// exports.default = exports;
// if(typeof module!=='undefined') {
//   module.exports = exports;
// }
