import axios from 'axios'
import {firestore, functions, firebase} from '@src/db'
import router from '@router'
import defaults from '@src/router/defaults'
import {FcmEvent} from '@common/FcmEvent';
import {apiErrorCode} from '@common/status';
import {eventHub} from "@src/eventHub";

const timeUtils = require ('@common/TimeUtils');
const Workspace = require ('@common/Workspace');


export const state = {
  currentUser: getSavedState('auth.currentUser'),
  currentCompany: getSavedState('auth.currentCompany'),
  currentCompanyId: getSavedState('auth.currentCompanyId'),
  currentCompanyMatrizId: getSavedState('auth.currentCompanyMatrizId'),
  isSuperAdminAccess: getSavedState('auth.isSuperAdminAccess'),
  currentWorkspace: getSavedState('auth.currentWorkspace'),
};

let unsubscribes = {};
let local= {};

export const userLoadedObj = new FcmEvent(10000);
export const userLoaded = userLoadedObj.promise;

userLoaded.then(()=>{
  console.log('TMP userLoaded Loaded');
});

class ErrorAuth extends Error {
  constructor(message, code) {
    super(message);
    this.message = message;
    this.code = code;
  }
  // this.name = 'MeuErro';
  // this.message = message || 'Mensagem de erro padrão';
  // this.stack = (new Error()).stack;
}

let unsubscribeOnAuthStateChanged = null;
export let ON_LOGIN = false;

function unsubscribeAll() {
  for(let i in unsubscribes) {
    if(typeof unsubscribes[i] === 'function') {
      unsubscribes[i]();
      unsubscribes[i]= null;
    }
  }
  unsubscribes = {};
  local = {};
}


function unsubscribe(name) {
  if(typeof unsubscribes[name] === 'function') {
    unsubscribes[name]();
    unsubscribes[name]= null;
  }
}


export const mutations = {
  SET_CURRENT_WORKSPACE(state, newValue) {
    state.currentWorkspace = newValue;
    saveState('auth.currentWorkspace', newValue);
  },
  SET_CURRENT_USER(state, newValue) {
    state.currentUser = newValue;
    saveState('auth.currentUser', newValue);
    setDefaultAuthHeaders(state)
  },
  SET_CURRENT_COMPANY(state, newValue) {
    state.currentCompany = newValue || null;
    saveState('auth.currentCompany', newValue);
    state.currentCompanyId = (newValue||{}).id || null;
    if(newValue?.isFilial) {
      state.currentCompanyMatrizId = newValue?.matrizId || null;
    }
    else {
      state.currentCompanyMatrizId = null;
    }
    state.isSuperAdminAccess = false;
    saveState('auth.currentCompanyId', state.currentCompanyId);
    saveState('auth.currentCompanyMatrizId', state.currentCompanyMatrizId);
    saveState('auth.isSuperAdminAccess', state.isSuperAdminAccess);
  },
  SET_CURRENT_COMPANY_SUPER_ADMIN(state, newValue) {
    state.currentCompany = newValue || null;
    saveState('auth.currentCompany', newValue);
    state.currentCompanyId = (newValue||{}).id || null;
    state.isSuperAdminAccess = true;
    if(newValue?.isFilial) {
      state.currentCompanyMatrizId = newValue?.matrizId || null;
    }
    else {
      state.currentCompanyMatrizId = null;
    }
    saveState('auth.currentCompanyMatrizId', state.currentCompanyMatrizId);
    saveState('auth.currentCompanyId', state.currentCompanyId);
    saveState('auth.isSuperAdminAccess', state.isSuperAdminAccess);
  },

  SET_CURRENT(state, {workspace, user, company, companyId, isSuperAdminAccess} = {}) {
    state.currentWorkspace = workspace || null;
    state.currentUser = user || null;
    state.currentCompany = company || null;
    state.isSuperAdminAccess = isSuperAdminAccess || false;

    state.currentCompanyId = (company||{}).id || companyId || null;

    if(company?.isFilial) {
      state.currentCompanyMatrizId = company?.matrizId || null;
    }
    else {
      state.currentCompanyMatrizId = null;
    }
    setDefaultAuthHeaders(state);
    saveState('auth.currentCompanyId', state.currentCompanyId);
    saveState('auth.currentCompanyId', state.currentCompanyId);
    saveState('auth.isSuperAdminAccess', state.isSuperAdminAccess);
    saveState('auth.currentWorkspace', workspace || null);
    saveState('auth.currentUser', user || null);
    saveState('auth.currentCompany', company || null);
  }
};

export const getters = {
  // Whether the user is currently logged in.
  loggedIn(state) {
    let isValid = false;
    switch(state.currentWorkspace) {
      case Workspace.name.COMPANY:
        if (state.currentUser && state.currentCompany) {
          isValid = true
        }
        break;
      case Workspace.name.ADMIN:
        if (state.currentUser) {
          isValid = true
        }
        break;
      case Workspace.name.CLIENT:
        if (state.currentUser) {
          isValid = true
        }
        break;
      default:
        if(state.currentUser) {
          console.warn('Inconsistencia encontrada, usuário sem workspace definido')
        }
    }
    if(isValid) {
      return state.currentWorkspace
    }
    return false
  },
};

export const actions = {
  // This is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  init({ commit, state, dispatch }) {
    if(!unsubscribeOnAuthStateChanged) {
      unsubscribeOnAuthStateChanged = firebase.auth().onAuthStateChanged((user) => {
        eventHub.emit('auth:firebaseOnAuthStateChanged', {firebaseUser: user});
        dispatch('onAuthStateChanged', {firebaseUser: user});
      });
    }
    setDefaultAuthHeaders(state);
    dispatch('validate');
  },
  async changeAuth({ dispatch }) {
    eventHub.emit('auth:changeAuth', null);
    await dispatch('changeCompany', null, {
      root: false
    });
  },
  async changeCompany({ dispatch }, arg) {
    eventHub.emit('auth:changeCompany', null);
  },

  /**
   *
   * @param commit
   * @param dispatch
   * @param getters
   * @param locale
   * @param provider  'password' | 'facebook.com' | 'google.com' | 'apple.com'
   * @param email
   * @param password
   * @param workspace
   * @returns {Promise<never>}
   */
  async logIn({ commit, dispatch, getters },{locale, provider, email, password, workspace=Workspace.name.CLIENT}) {
   // if (getters.loggedIn) return dispatch('validate')
    ON_LOGIN = true;
    let authUser =  false;
    try {
      if(provider==='password') {
        if(!email) {
          return Promise.reject(new ErrorAuth('', 'missingEmail'));
        }
        if(!password) {
          return Promise.reject(new ErrorAuth('', 'missingPassword'));
        }
        authUser = await firebase.auth().signInWithEmailAndPassword(email, password);
      }
      else {
        let providerObj = null;
        switch(provider) {
          case 'facebook.com':
            providerObj = new firebase.auth.FacebookAuthProvider();
            break;
          case 'google.com':
            providerObj = new firebase.auth.GoogleAuthProvider();
            break;
          case 'apple.com':
            providerObj = new firebase.auth.OAuthProvider('apple.com');
            break;
          default:
            return Promise.reject(new Error('Unknown provider'));
        }
        if(locale) {
          providerObj.setCustomParameters({
            locale: locale
          });
        }
        authUser = await firebase.auth().signInWithPopup(providerObj)
      }
    } catch(error) {
      console.error('Erro em LogIn', error);
      ON_LOGIN = false;
      return Promise.reject(error);
    }
    await dispatch('onAuthStateChangedLogin', {firebaseUser: authUser.user, provider: provider, isClient: true, workspace: workspace});
    ON_LOGIN = false;
  },

  async logInPhone({ commit, dispatch, getters }, {authUser}) {
    await dispatch('onAuthStateChangedLogin', {firebaseUser: authUser.user, provider: 'phone', isClient: true})
    ON_LOGIN = false
  },

  async getAdminMailByFakeMail({commit}, {email}) {
    let userList = await firestore.collection('companiesAdminPublicMirror').where("email", "==", email.toLowerCase()).get();
    if(!userList) {
      return Promise.reject(new Error('Nenhuma empresa encontrada'));
    }
    let fakeMail = false
    userList.forEach(function(doc) {
      let user = doc.data();
      if(user.fakeMail) {
        fakeMail = user.fakeMail;
      }
    });
    return fakeMail;
  },

  async listCompanies({ commit, dispatch, getters }, { email } = {}) {
    if (getters.loggedIn) return dispatch('validate')

    let userList = await firestore.collection('companiesUsersPublicMirror').where("email", "==", email.toLowerCase()).get();
    if(!userList) {
      return Promise.reject(new Error('Nenhuma empresa encontrada'));
    }

    let promises = [];
    let hasCompany = false;
    let ret = {};

    userList.forEach(function(doc) {
      let user = doc.data();
      if(!user.companyId ||!user.fakeMail) {
        return;
      }
      let companyId = user.companyId;
      let userId = doc.id;

      ret[userId] = {
        company: false,
        fakeMail: user.fakeMail
      };
      promises.push( new Promise(async function(resolve, reject){
        let companyDoc = await firestore.collection('companies').doc(companyId).get();
        if(companyDoc.exists) {
          let companyTmp = companyDoc.data();
          companyTmp.id = companyDoc.id;
          ret[userId]['company'] = companyTmp;
          hasCompany = true;
        }
        resolve();
      }));
    });

    await Promise.all(promises);
    for(let i in ret) {
      if(!ret[i].company) {
        delete ret[i];
      }
    }

    if(hasCompany) {
      return ret;
    }
    return false;
  },
  async modelCompanyUpdate({ commit, dispatch, getters, state }, {form = {},itemId, isSuperAdmin=false}={}) {
    if(!form || !itemId) {
      return Promise.reject(new Error('id não encontrado'))
    }

    let companyId = itemId;
    let company = null;
    if(isSuperAdmin) {
      let companyObj = await firestore.collection('companies').doc(companyId).get();
      company = Object.assign({}, companyObj.data(), form);
      delete form.companyId;
      form.id = companyId;
    }
    else {
      if(itemId!==state.currentCompany.id) {
        return Promise.reject(new Error('id inválido', itemId, state.currentCompany.id));
      }
      companyId = state.currentCompany.id;
      company = Object.assign({}, state.currentCompany, form);
      delete form.companyId;
      form.id = companyId;
      commit('SET_CURRENT_COMPANY', company);
    }
    await firestore.collection('companies').doc(companyId).set(form,{merge: true})
  },
  async modelCompanyUserUpdate({ commit, dispatch, getters, state }, form = {}) {
    if(!form || !form.id) {
      return Promise.reject(new Error('id não encontrado'))
    }
    if(state.currentUser.id===form.id) { // self update
      let user = Object.assign({}, state.currentUser, form);
      commit('SET_CURRENT_USER', user)
    }
    let userId = form.id;
    delete form.id;
    await firestore.collection('companiesUsers').doc(userId).set(form,{merge: true})
  },
  async modelClientUpdate({ commit, dispatch, getters, state }, form = {}) {
    let userId = state.currentUser.id;
    let user = Object.assign({}, state.currentUser, form);
    delete form.id;
    commit('SET_CURRENT_USER', user)
    await firestore.collection('users').doc(userId).set(form,{merge: true})
  },
  async modelAdminUpdate({ commit, dispatch, getters, state }, form = {}) {
    let userId = state.currentUser.id;
    let user = Object.assign({}, state.currentUser, form);
    delete form.id;
    commit('SET_CURRENT_USER', user)
    await firestore.collection('adminUsers').doc(userId).set(form,{merge: true})
  },

  async registerClient({ commit, dispatch, getters },
                       { name,
                         email,
                         password
                       } = {}) {
    if (getters.loggedIn) {
      return dispatch('validate')
    }
    ON_LOGIN = true;
    let authUser = null;
    try {
      authUser = await firebase.auth().createUserWithEmailAndPassword(email, password);
      console.log('authUser', authUser);
      await firestore.collection('users').doc(authUser.user.uid).set({email: email, name: name||''},{merge: true})
      authUser = await firebase.auth().signInWithEmailAndPassword(email, password);
    } catch(error) {
      console.error('Erro em signInWithEmailAndPassword', error)
      ON_LOGIN = false;
      return Promise.reject(error);
    }

    await dispatch('onAuthStateChangedLogin', {firebaseUser: authUser.user, provider: 'email'});
    ON_LOGIN = false;
    return 'ok'
  },

  async companyRegister({ commit, dispatch, getters },
        { name,
          email,
          password,
          companyName
        } = {}) {
    if (getters.loggedIn) {
      return dispatch('validate')
    }
    ON_LOGIN = true;
    let result = await functions.httpsCallable('auth-companyRegister')({name, email, password, companyName});
    console.log('result', result);
    let authUser = null;

    try {
      authUser = await firebase.auth().signInWithEmailAndPassword(result.data.fakeMail, password);
    } catch(error) {
      console.error('Erro em signInWithEmailAndPassword', error);
      ON_LOGIN = false;
      return Promise.reject(error);
    }

    await dispatch('onAuthStateChangedLogin', {firebaseUser: authUser.user, provider: 'email'});
    ON_LOGIN = false;
    return 'ok'
  },

  // Logs out the current user.
  logOut({ commit, dispatch }) {
    return dispatch('makeLogOut')

  },

  // Validates the current user's token and refreshes it
  // with new data from the API.
  async validate({ commit, state, dispatch }, {workspaces, rules}= {}) {
    // ADMIN tem acesso ao workspace COMPANY
    if(state.currentWorkspace!==Workspace.name.ADMIN &&
      workspaces?.length>0 && !workspaces?.includes?.(state.currentWorkspace)) {
      console.log('workspace', workspaces);
      console.log('state.currentWorkspace', state.currentWorkspace);
      console.log('state.currentUser', state.currentUser);
      await dispatch('makeLogOut');
      return {isOk: false};
    }

    let isValid = false;
    switch(state.currentWorkspace) {
      case Workspace.name.COMPANY:
        if (state.currentUser && state.currentCompany) {
          isValid = true;
        }
        break;
      case Workspace.name.ADMIN:
        if (state.currentUser) {
          isValid = true;
        }
        break;
      case Workspace.name.CLIENT:
        if (state.currentUser) {
          isValid = true;
        }
        break;
      default:
        if(state.currentUser) {
          console.warn('Inconsistencia encontrada, usuário sem workspace definido');
        }
    }

    if (!isValid){
      await dispatch('makeLogOut');
      return {isOk: false};
    }
    // TODO Ao acessar uma URL inválida, o usuário é desconectado
/*    if(rules && rules.length>0) {
      isValid = false;
      if(!state.currentUser.rules) {
        console.warn('Acesso requerido');
        router.push({name: defaults.routeLogged[workspace] || 'login'});
        return false;
      }
      for(let r of rules) {
        if(state.currentUser.rules[r]) {
          isValid = true;
          break;
        }
      }
      if (!isValid){
        console.warn('Acesso requerido');
        router.push({name: defaults.routeLogged[workspace] || 'login'})
        return false;
      }
    } */


    return {isOk: true, user: state.currentUser, currentCompanyId: state.currentCompanyId};
  },

  async onAuthStateChangedLogin({ commit, getters, state, dispatch }, {firebaseUser, provider, isClient}) {
    if (firebaseUser && firebase.auth().currentUser) {
      let workspace = state.currentWorkspace;

      if(!workspace) {
        let idTokenResult = await firebase.auth().currentUser.getIdTokenResult();
        if(idTokenResult && idTokenResult.claims && idTokenResult.claims.type) {
          if(idTokenResult.claims.type.cli) {
            workspace = Workspace.name.CLIENT
          }
          else if(idTokenResult.claims.type.adm) {
            workspace = Workspace.name.ADMIN
          }
          else if(idTokenResult.claims.type.comp) {
            workspace = Workspace.name.COMPANY
          }
          else {
            console.warn('idTokenResult', idTokenResult)
          }
        }
      }

      if(!workspace) {
        console.log('buscar workspace');
        let result = await functions.httpsCallable('auth-checkWorkspace')({id: firebaseUser.uid});
        if(result.data && result.data.errorCode===apiErrorCode.OK && result.data.data) {
          if(result.data.data.type.cli) {
            workspace = Workspace.name.CLIENT
          }
          else if(result.data.data.type.adm) {
            workspace = Workspace.name.ADMIN
          }
          else if(result.data.data.type.comp) {
            workspace = Workspace.name.COMPANY
          }
          else {
            console.warn('auth-checkWorkspace', result)
          }
          await firebase.auth().currentUser.getIdToken(true)
        }
        else {
          console.error('not workspace', firebaseUser)
          return dispatch('makeLogOut')
        }
      }

      switch(workspace) {
        case Workspace.name.COMPANY:
          return dispatch('loadUserCompany', firebaseUser.uid)
        case Workspace.name.ADMIN:
          const promisesAdmin = [];
          promisesAdmin.push(dispatch('loadUserAdmin', firebaseUser.uid));
          if(state.companyId) {
            promisesAdmin.push(dispatch('loadCompanyOnAdmin', state.companyId));
          }
          return Promise.all(promisesAdmin);
        case Workspace.name.CLIENT:
          return dispatch('loadUserClient', firebaseUser.uid)
      }

      if(isClient) {
        console.warn('ERRO ao procurar workspace do usuário, criando client: '+workspace, firebaseUser)
        await firestore.collection('users').doc(firebaseUser.uid).set({
          createdEnv: 'WEB',
          createdType: provider || 'unknown'
        }, {merge: true})

        let result = await functions.httpsCallable('auth-checkWorkspace')({uid: firebaseUser.uid})
        if(result.data && result.data.errorCode===apiErrorCode.OK && result.data.data && result.data.data.type) {
          if(result.data.data.type.cli) {
            workspace = Workspace.name.CLIENT
          }
          else if(result.data.data.type.adm) {
            workspace = Workspace.name.ADMIN
          }
          else if(result.data.data.type.comp) {
            workspace = Workspace.name.COMPANY
          }
          else {
            console.warn('Erro ao buscar workspace, result', result);
          }
          await firebase.auth().currentUser.getIdToken(true);
        }
        if(workspace) {
          await dispatch('loadUserClient', firebaseUser.uid);
          return dispatch('changeAuth');
        }
      }
      console.error('ERRO ao procurar workspace do usuário: '+workspace, firebaseUser);
      return dispatch('makeLogOut');
    } else {
      console.error('not firebaseUser', firebaseUser);
      return dispatch('makeLogOut');
    }
  },

  async onAuthStateChanged({ commit, getters, state, dispatch }, {firebaseUser}) {
    if(ON_LOGIN) {
      return;
    }

    if (firebaseUser && firebase.auth().currentUser) {
      let workspace = state.currentWorkspace

      if(!workspace) {
        let idTokenResult = await firebase.auth().currentUser.getIdTokenResult()
        if(idTokenResult && idTokenResult.claims && idTokenResult.claims.type) {
          if(idTokenResult.claims.type.cli) {
            workspace = Workspace.name.CLIENT
          }
          else if(idTokenResult.claims.type.adm) {
            workspace = Workspace.name.ADMIN
          }
          else if(idTokenResult.claims.type.comp) {
            workspace = Workspace.name.COMPANY
          }
          else {
            console.warn('idTokenResult', idTokenResult)
          }
        }
      }

      if(!workspace) {
        console.warn('buscar workspace');
        let result = await functions.httpsCallable('auth-checkWorkspace')({id: firebaseUser.uid});
        if(result.data && result.data.errorCode===apiErrorCode.OK && result.data.result && result.data.result.type) {
          if(result.data.data.type.cli) {
            workspace = Workspace.name.CLIENT;
          }
          else if(result.data.data.type.adm) {
            workspace = Workspace.name.ADMIN;
          }
          else if(result.data.data.type.comp) {
            workspace = Workspace.name.COMPANY;
          }
          else {
            console.warn('Erro ao buscar workspace, result', result)
          }
          await firebase.auth().currentUser.getIdToken(true)
        }
      }

      let loaded = false;
      switch(workspace) {
        case Workspace.name.COMPANY:
          loaded = true;
          await dispatch('loadUserCompany', firebaseUser.uid);
          break;
        case Workspace.name.ADMIN:
          loaded = true;
          await dispatch('loadUserAdmin', firebaseUser.uid);
          break;
        case Workspace.name.CLIENT:
          loaded = true;
          await dispatch('loadUserClient', firebaseUser.uid);
          break;
      }

      if(!loaded) {
        console.error('ERRO ao procurar workspace do usuário', firebaseUser);
        // TODO TMP
      //  return dispatch('makeLogOut');
      }
      return dispatch('changeAuth');
    } else {
      return dispatch('makeLogOut');
    }
  },

  loadUserCompany({ commit, getters, state, dispatch }, userId) {
    return new Promise(function(resolve, reject){
      if(local.companyUserId && local.companyUserId===userId) {
        console.log('loadUserCompany SKIPPED');
        return resolve();
      }
      unsubscribeAll();
      local.companyUserId = userId;
      unsubscribes.companyUser= firestore.collection('companiesUsers').doc(userId)
      .onSnapshot(function(userDoc) {
        let userData = userDoc.data();
        if(!userData || !userData.companyId) {
          dispatch('makeLogOut')
          console.error('Usuário sem companyId ou userData userId: '+userId);
          return reject(new Error('Usuário sem companyId ou userData userId: '+userId))
        }
        userData.id = userId

        local.companyId = local.companyUserId;
        unsubscribes.company= firestore.collection('companies').doc(userData.companyId)
        .onSnapshot(function(companyDoc) {
          let companyData = companyDoc.data()
          if(!companyData) {
            dispatch('makeLogOut')
            userLoadedObj.reject();
            console.error('company não encontrada userId: '+userId + ' companyId:'+userData.companyId);
            return reject(new Error('company não encontrada userId: '+userId + ' companyId:'+userData.companyId))
          }
          companyData.id = companyDoc.id
          commit('SET_CURRENT', {
            workspace: Workspace.name.COMPANY,
            user: userData,
            company: companyData
          });
          userLoadedObj.resolve();
          resolve();
        })
      });
    });
  },
  async loadCompanyOnClient({ commit, getters, state, dispatch }, companyId) {
    return new Promise(function(resolve, reject){
      if(unsubscribes.company && local.companyId && local.companyId===companyId) {
        console.log('loadCompanyOnClient SKIPPED');
        return resolve();
      }
      unsubscribe('company');
      if(!companyId) {
        commit('SET_CURRENT_COMPANY', null);
        dispatch('changeCompany');
        resolve();
      }
      local.companyId = companyId;
      unsubscribes.company= firestore.collection('companies').doc(companyId)
        .onSnapshot(function(companyDoc) {
          const companyData = companyDoc.data();
          companyData.id = companyId;
          if(!companyData) {
            dispatch('makeLogOut')
            console.error('company não encontrada companyId:'+companyId);
            return reject(new Error('company não encontrada companyId:'+companyId));
          }
          companyData.id = companyDoc.id
          commit('SET_CURRENT_COMPANY', companyData);
          dispatch('changeCompany');
          resolve();
        })
    });
  },
  async loadCompanyOnAdmin({ commit, getters, state, dispatch }, companyId) {
    return new Promise(function(resolve, reject){
      if(unsubscribes.company && local.companyId && local.companyId===companyId) {
        console.log('loadCompanyOnAdmin SKIPPED');
        return resolve();
      }
      unsubscribe('company');
      if(!companyId) {
        commit('SET_CURRENT_COMPANY_SUPER_ADMIN', null);
        dispatch('changeCompany');
        resolve();
      }
      local.companyId = companyId;
      unsubscribes.company= firestore.collection('companies').doc(companyId)
        .onSnapshot(function(companyDoc) {
          const companyData = companyDoc.data();
          companyData.id = companyId;
          if(!companyData) {
            dispatch('makeLogOut')
            console.error('company não encontrada companyId:'+companyId);
            return reject(new Error('company não encontrada companyId:'+companyId));
          }
          companyData.id = companyDoc.id
          commit('SET_CURRENT_COMPANY_SUPER_ADMIN', companyData);
          dispatch('changeCompany');
          resolve();
        })
    });
  },

  loadUserClient({ commit, getters, state, dispatch }, userId) {
    return new Promise(function(resolve, reject){
      if(userId===local.unsubscribeUserClientId) {
        return resolve();
      }
      unsubscribeAll();
      local.unsubscribeUserClientId = userId;
      unsubscribes.client = firestore.collection('users').doc(userId)
      .onSnapshot(function(userDoc) {
        let userData = userDoc.data();
        if(!userData) {
          dispatch('makeLogOut')
          userLoadedObj.reject();
          console.error('Usuário sem userData userId: '+userId);
          return reject(new Error('Usuário sem userData userId: '+userId));
        }
        userData.id = userId;
        commit('SET_CURRENT_WORKSPACE', Workspace.name.CLIENT);
        commit('SET_CURRENT_USER', userData);
        userLoadedObj.resolve();
        console.log('TODO, validar se o usuário atual tem acesso a empresa selecionada', state);
        if(state.currentCompanyId) {
          dispatch('loadCompanyOnClient', state.currentCompanyId);
        }
        else {
          commit('SET_CURRENT_COMPANY', null);
        }
        resolve();
      });
    });
  },
  loadUserAdmin({ commit, getters, state, dispatch }, userId) {
    return new Promise(function(resolve, reject){
      if(userId===local.unsubscribeUserAdminId) {
        return resolve();
      }
      unsubscribeAll();
      local.unsubscribeUserAdminId = userId;
      unsubscribes.userAdmin = firestore.collection('adminUsers').doc(userId)
      .onSnapshot(function(userDoc) {
        let userData = userDoc.data()
        if(!userData) {
          dispatch('makeLogOut');
          console.error('Usuário sem userData userId: '+userId);
          return reject(new Error('Usuário sem userData userId: '+userId));
        }
        userData.id = userId;
        commit('SET_CURRENT_WORKSPACE', Workspace.name.ADMIN);
        commit('SET_CURRENT_USER', userData);
        userLoadedObj.resolve();
        resolve();
      });
    });
  },

  async makeLogOut({commit, dispatch}, {disableRedirect} = {}) {
    unsubscribeAll();
    commit('SET_CURRENT')
    if(firebase.apps.length>0) {
      try {
        await firebase.auth().signOut();
      } catch(error) {
        // An error happened.
        console.error('Error on logout', error);
      }
    }
    if(router && router.currentRoute && router.currentRoute.meta && router.currentRoute.meta.authRequired) {
      let workspace = router.currentRoute.meta.workspace;
      console.log(defaults.routeNotLogged[workspace] || 'login');
      await router.push({name: defaults.routeNotLogged[workspace] || 'login'})
    }
    await dispatch('changeAuth');
  },

  async modelOngUpdate({ commit, dispatch, getters, state }, {form = {},itemId}={}) {
    if(!form || !itemId) {
      return Promise.reject(new Error('id não encontrado'))
    }

    if(itemId!==state.currentOng.id) {
      return Promise.reject(new Error('id inválido', itemId, state.currentOng.id));
    }

    let ongId = state.currentOng.id;
    let ong = Object.assign({}, state.currentOng, form);

    delete form.ongId;
    form.id = ongId;
    commit('SET_CURRENT_ONG', ong);
    await firestore.collection('ongs').doc(ongId).set(form,{merge: true})
  },
  async modelOngUserUpdate({ commit, dispatch, getters, state }, form = {}) {
    if(!form || !form.id) {
      return Promise.reject(new Error('id não encontrado'))
    }
    if(state.currentUser.id===form.id) { // self update
      let user = Object.assign({}, state.currentUser, form);
      commit('SET_CURRENT_USER', user)
    }
    let userId = form.id;
    delete form.id;
    await firestore.collection('ongsUsers').doc(userId).set(form,{merge: true})
  },
  async ongRegister({ commit, dispatch, getters },
                        { name,
                          email,
                          password,
                          ongName
                        } = {}) {
    if (getters.loggedIn) {
      return dispatch('validate')
    }
    ON_LOGIN = true;
    let result = await functions.httpsCallable('auth-ongRegister')({name, email, password, ongName});
    console.log('result', result);
    let authUser = null;

    try {
      authUser = await firebase.auth().signInWithEmailAndPassword(result.data.fakeMail, password);
    } catch(error) {
      console.error('Erro em signInWithEmailAndPassword', error);
      ON_LOGIN = false;
      return Promise.reject(error);
    }

    await dispatch('onAuthStateChangedLogin', {firebaseUser: authUser.user, provider: 'email'});
    ON_LOGIN = false;
    return 'ok'
  },



};

// ===
// Private helpers
// ===

function getSavedState(key) {
  return JSON.parse(window.localStorage.getItem(key))
}

function saveState(key, state) {
  window.localStorage.setItem(key, JSON.stringify(state, function(k, v) {
    if(typeof v ==='object' && v && (v.wa==='FieldValue.serverTimestamp' || v.fa==='FieldValue.serverTimestamp') || k==='updatedAt') {
      return null;
    }
    return v;
  }))
}

function setDefaultAuthHeaders(state) {
  axios.defaults.headers.common.Authorization = state.currentUser
    ? state.currentUser.token
    : ''
}

