<script>
  const colorUtils = require ('@common/ColorUtils');
  const defaultColor = {r:255,g:255,b:255,a:1};

  export default {
    name: 'FcmColorPick',
    components: {},
    model: {
      prop: 'value',
      event: 'input'
    },
    props: {
      value: {
        type: [String, Number, Object],
        default: '',
      },
      title: {
        type: String,
        default: ''
      },
      label: {
        type: String,
        default: ''
      },
    },
    data(vm) {
      return {
        showModal: false,
        items: [],
        internalLazyValue: vm.value || _.cloneDeep(defaultColor),
      }
    },
    computed: {
      colorBackground: {
        get() {
          return colorUtils.rgba2css(this.internalLazyValue);
        }
      },
      internalValuePicker: {
        get() {
          return this.internalLazyValue || {};
        },
        set(val) {
          if (val=== this.internalLazyValue) {return;}
          this.internalLazyValue = val;
          this.$emit('input', val||_.cloneDeep(defaultColor));
        }
      }
    },
    watch: {
      showModal(value) {
        if(!value) {
          this.$emit('dialogClose', null);
        }
      },
      value(val) {
        if(_.isEqual(val, this.internalLazyValue)){
          return;
        }
        this.internalLazyValue = val || _.cloneDeep(defaultColor);
        this.$emit('input', this.internalLazyValue);
      },
    },
    mounted: async function() {
    },
    beforeDestroy: function() {
    },
    methods: {
      closeModal() {
        this.showModal = false;
      },
      openModal() {
        this.showModal = true;
      },
      closeAction() {
        this.showModal = false;
      },
      saveAction() {
        this.$emit('dialogConclude', null);
        this.showModal = false;
      },
    },
  }
</script>


<template>
  <div :class="$style.box">
    <div :class="$style.container" @click="openModal">
      <div :class="[$style.colorBg, 'v-elevation-4']" :style="'background-color:'+colorBackground+';'"></div>
      <div v-if="label" :class="$style.text">{{label}}</div>
    </div>
    <VDialog
        v-model="showModal"
        :fullscreen="$vuetify.breakpoint.xs"
        scrollable
        max-width="300px"
        :content-class="$style.modal"
    >
      <VCard tile>
        <VToolbar
            flat
            :class="$style.header"
        >
          <VBtn
              icon
              @click="closeAction"
          >
            <VIcon>mdi-close</VIcon>
          </VBtn>
          <VToolbarTitle v-if="title">{{title}}</VToolbarTitle>
          <VSpacer></VSpacer>
          <VToolbarItems>
            <VBtn
                text
                :class="$style.buttonSave"
                @click="saveAction"
            >
              {{$t('FcmComponents.conclude')}}
            </VBtn>
          </VToolbarItems>
        </VToolbar>
        <VCardText :class="$style.body">
          <VColorPicker
              v-model="internalValuePicker"
              flat
              mode="rgba"
          ></VColorPicker>
        </VCardText>

      </VCard>
    </VDialog>
  </div>
</template>


<style lang="scss" module>
  @import '@design';
  .modal {
    background-color: var(--v-modalBg-base);

    .header {
      background-color: var(--v-modalHeaderBg-base)!important;
      .buttonSave {
        color: var(--v-buttonBg-base)!important;
      }
      flex: 0;
    }

    .body {
      padding: 13px 5px 6px!important;
      flex: 0;
      @media screen and (max-height: 1000px) {
        height: 800px;
      }
      .fcmList {
        height: 100%;
      }
    }
    box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 24%), 0px 24px 38px 3px rgb(10 10 10 / 14%), 0px -45px 40px -6px rgb(58 58 58 / 12%);


  }

  .box {
    .container {
      cursor: pointer;
      display: flex;
      align-items: center;
      .colorBg {
        width: 30px;
        height: 30px;
        border-radius: 3px;
      }
      .text {
        margin-left: 10px;
      }
    }
  }
</style>





















