"use strict";

const _ = require('lodash');
let toExport = {};

// levenshtein alterado para substring, nesse algoritmo a ordem importa
toExport.fcmLevenshtein = function(source, target) {
  const result = []; // This is going to be a matrix. The result we're looking for can be found by flood-filling it.
  let i, j;

  // Let's fill the first line of the matrix with distances from each substring of the source to the empty string.
  for (i = 0; i <= source.length; i++) {
    result.push([i]);
  }

  // Same as above, but for a column. Also, the first element of the first column is already filled with a zero, hence j = 1.
  for (j = 1; j <= target.length; j++) {
    result[0].push(0);
  }

  for (i = 1; i <= source.length; i++) {
    for (j = 1; j <= target.length; j++) {

      // the element in the iteration doesn't exist yet. Let's create it.
      result[i].push(0);

      // Now let's get an edit distance between two characters.
      if (source[i-1] === target[j-1]) {

        // Notice how, for a comparison between two equal strings, the diagonal will be filled with zeroes.
        result[i][j] = result[i-1][j-1];
      } else {

        // Different characters. The formulae for edit distances are shown below.
        let minimum = Math.min(
          result[i-1][j] + 1, // This means we can bring the target string closer to the source one with a character deletion
          result[i][j-1] + 1  // Same as above, but with a character insertion
        );

        minimum = Math.min(
          minimum,
          result[i-1][j-1] + 1 // This means that we can bring the target closer to the source by means of a character change.
        );
        result[i][j] = minimum;
      }
    }
  }

  let fcmMin = target.length;
  for (j = 1; j <= target.length; j++) {
    if(fcmMin>result[source.length][j]) {
      fcmMin = result[source.length][j];
    }
  }
  return fcmMin;

  // The actual distance we're looking for is the value stored in the lower right corner of the matrix.
  // return result[source.length][target.length];
};



// É esperado as strings em lowercase e sem espaço no inicio e no fim
// let score = stringUtils.fcmlevenshteinNormalizeScore('start Buc', 'cafe starbucks - brasil');
// source.toLowerCase().trim();
toExport.fcmlevenshteinNormalizeScore = function(source, target) {
  if(!source) {return 0;}
  if(!target) {return 0;}
  let cont = 0;
  let scoreSearch = source.split(' ').reduce(function(o, term) {
    if(!term) {
      return o;
    }
    cont++;
    return o+(1-(toExport.fcmLevenshtein(term, target)/term.length));
  }, 0);
  scoreSearch /= cont || 1;
  return scoreSearch;
};


toExport.toTitleCase = (phrase) => {
  if(!phrase) {return '';}
  return phrase
  .toLowerCase()
  .split(' ')
  .map(word => word.charAt(0).toUpperCase() + word.slice(1))
  .join(' ');
};


toExport.username = (phrase) => {
  if (!phrase) {
    return '';
  }
  phrase =_.deburr(phrase);

  return phrase.replace(/[^A-Za-z\d_-]/g, '');
};

toExport.deburr = (txt) => {
  return _.deburr(txt||'').toLowerCase().trim();
};

toExport.deburrSlug = (txt) => {
  return (txt ||'').replace(/[^\dA-Za-z\-_]/g, '').trim();
};

toExport.deburrCpf = (txt) => {
  return (txt||'').replace(/[^\d]/g, '').trim();
};

toExport.maskCPF = (text) => {
  const t = toExport.deburrCpf(text||'');
  return `${t[0]||''}${t[1]||''}${t[2]||''}.${t[3]||''}${t[4]||''}${t[5]||''}.${t[6]||''}${t[7]||''}${t[8]||''}-${t[9]||''}${t[10]||''}`;
}


toExport.shuffle = function (input) {
  let a = input.split(""),
    n = a.length;

  for(let i = n - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1));
    let tmp = a[i];
    a[i] = a[j];
    a[j] = tmp;
  }
  return a.join("");
};


toExport.getFragment = function(key, numberOfFragments) {
  let sum = 0;
  for(let i in key) {
    sum+=(key.charCodeAt(i)||0);
  }
  return sum%numberOfFragments;
}


toExport.validateCpf = function(value) {
  if (!value) {
    return true;
  }
  value = toExport.deburrCpf(value);

  let sum;
  let rest;
  let returnInvalid = false;
  sum = 0;
  if (value === "00000000000")
    returnInvalid = true;

  for (let i = 1; i <= 9; i++)
    sum = sum + parseInt(value.substring(i - 1, i)) * (11 - i);

  rest = (sum * 10) % 11;

  if ((rest === 10) || (rest === 11))
    rest = 0;
  if (rest !== parseInt(value.substring(9, 10)))
    returnInvalid = true;

  sum = 0;
  for (let i = 1; i <= 10; i++)
    sum = sum + parseInt(value.substring(i - 1, i)) * (12 - i);

  rest = (sum * 10) % 11;

  if ((rest === 10) || (rest === 11))
    rest = 0;
  if (rest !== parseInt(value.substring(10, 11)))
    returnInvalid = true;

  if (returnInvalid) {
    return false;
  }
  return true;
}

toExport.fallbackCopyTextToClipboard = function (text) {
  const textArea = document.createElement("textarea");
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    const successful = document.execCommand('copy');
    const msg = successful ? 'successful' : 'unsuccessful';
    console.log('Fallback: Copying text command was ' + msg);
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
}
toExport.copyTextToClipboard = async function (text) {
  if(typeof navigator==='undefined') {
    console.error('Async: Could not copy text!');
    return false;
  }
  if (!navigator.clipboard) {
    return toExport.fallbackCopyTextToClipboard(text);
  }
  try {
    await navigator.clipboard.writeText(text);
    return true
  } catch(err) {
    console.error('Async: Could not copy text: ', err);
  }
  return false;
}

toExport.json2csv = function (items) {
  let ret = '';
  for(const row of items) {
    for(const item of row) {
      let value = item;
      if(typeof value==='string') {
        value = '"'+value+'"';
      }
      ret += value + ';';
    }
    ret += '\n';
  }
  return ret;
}

toExport.formatPhone = function (phone, hasContry=false) {
  if (!phone?.length) {
    return '';
  }
  if (hasContry) {
    phone = phone.substring(3);
  }
  return `(${phone.substring(0,2)}) ${phone.substring(2,6)}-${phone.substring(7)}`
}


module.exports = toExport;

// exports.default = exports;
// if(typeof module!=='undefined') {
//   module.exports = exports;
// }

