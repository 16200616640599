import Vue from 'vue';
import VueRouter from 'vue-router';
// https://github.com/declandewet/vue-meta
import VueMeta from 'vue-meta';
// Adds a loading bar at the top during page loads.
import NProgress from 'nprogress/nprogress';
import store from '@src/store';
import routes from './routes';
import defaults from '@src/router/defaults';
import vuetify from "@src/plugins/vuetify";
const Workspace = require ('@common/Workspace');
let {getLocale, setLocale, languages} = require('@i18n');

Vue.use(VueRouter);

Vue.use(VueMeta, {
  // The component option name that vue-meta looks for meta info on.
  keyName: 'page',
});

const router = new VueRouter({
  routes,
  // Use the HTML5 history API (i.e. normal-looking routes)
  // instead of routes with hashes (e.g. example.com/#/about).
  // This may require some server configuration in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations

  mode: process.env.IS_ELECTRON ? 'hash' : 'history',
  // (VUE 3 - you will need to import these functions from 'vue-router')
  // history: process.env.IS_ELECTRON ? createWebHashHistory() : createWebHistory(),

  // Simulate native-like scroll behavior when navigating to a new
  // route and using back/forward buttons.
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    else if (to.hash) {
      return {selector: to.hash};
    }
    else {
      return { x: 0, y: 0 }
    }
  },
})

// Before each route evaluates...
router.beforeEach((routeTo, routeFrom, next) => {
//  console.log('beforeEach', routeTo);
  // If this isn't an initial page load...
  if (routeFrom.name !== null) {
    // Start the route progress bar.
    NProgress.start();
  }

  if(routeTo.query.locale || routeTo.query.lang) {
    let locale = getLocale(routeTo.query.locale || routeTo.query.lang);
    setLocale(locale);
    if(window && window.VueI18n) {
      window.VueI18n.locale = locale;
      if(languages[locale]) {
        window.VueI18n.localeDatabase = languages[locale].database;
        if(router.app && router.app.$vuetify && router.app.$vuetify.theme) {
          router.app.$vuetify.lang.current = languages[locale].vuetify;
        }
        else if(vuetify && vuetify.framework && vuetify.framework.theme) {
          vuetify.framework.lang.current = languages[locale].vuetify;
        }
      }
    }
    if(document && document.documentElement) {
      document.documentElement.setAttribute('lang', locale);
    }
    delete routeTo.query.locale;
    delete routeTo.query.lang;
    return next({...routeTo});
  }
/*

  if(routeTo.query.isDark) {
    let isDark = JSON.parse(routeTo.query.isDark) || false;
    if(router.app && router.app.$vuetify && router.app.$vuetify.theme) {
      router.app.$vuetify.theme.isDark = isDark;
    }
    if(vuetify && vuetify.framework && vuetify.framework.theme) {
      vuetify.framework.theme.isDark = isDark;
    }
    if(typeof window!=='undefined' && window.localStorage) {
      window.localStorage.setItem('darkMode', isDark?'true':'false');
    }

    delete routeTo.query.isDark;
   // router.replace(routeTo);
    return next({...routeTo})
  }
*/

  // Check if auth is required on this route
  // (including nested routes).
  const authRequired = routeTo.matched.some((route) => route.meta.authRequired)

  // If auth isn't required for the route, just continue.
  if (!authRequired) return next()

  let workspaces = false;
  let rules = [];
  let needCompany = false;
  if(routeTo.matched[0] && routeTo.matched[0].meta) {
    workspaces = routeTo.matched[0].meta.workspaces || [];
    rules = routeTo.matched[0].meta.rules || [];
    needCompany = routeTo.matched[0].meta.needCompany || false;
  }

  // If auth is required and the user is logged in...
  if (store.getters['auth/loggedIn']) {
    // Validate the local user token...
    return store.dispatch('auth/validate', {workspaces, rules}).then(({isOk, user, currentCompanyId}) => {
      // Then continue if the token still represents a valid user,
      // otherwise redirect to login.
      if(!isOk) {
        redirectToLogin(workspaces);
        return false;
      }
      if(rules && rules.length>0) {
        let isValid = false;
        if(user.rules) {
          for(let r of rules) {
            if(user.rules[r]) {
              isValid = true;
              break;
            }
          }
        }
        if (!isValid){
          console.warn('accessDenied');
          if(window.vueApp && window.vueApp.$swal && window.vueApp.$t) {
            window.vueApp.$swal({
              toast: true,
              position: 'top-end',
              icon: 'error',
              title: window.vueApp.$t('router.accessDenied'),
              showConfirmButton: false,
              timer: 2000
            });
          }

          if(routeTo.name!==defaults.routeLogged[workspaces?.[0]] ) {
            next({ name: defaults.routeLogged[workspaces?.[0]] || 'login' });
          }
          else {
            next();
          }
          return false;
        }
      }
      if(needCompany && !currentCompanyId) {
        console.warn('Need Company');
        if(window.vueApp && window.vueApp.$swal && window.vueApp.$t) {
          window.vueApp.$swal({
            toast: true,
            position: 'top-end',
            icon: 'warning',
            title: window.vueApp.$t('router.needCompany'),
            showConfirmButton: false,
            timer: 2000
          });
        }

        if(routeTo.name!==defaults.companySelect[workspaces?.[0]] ) {
          next({ name: defaults.companySelect[workspaces?.[0]] || 'login', query: { redirectFrom: routeTo.fullPath } });
        }
        else {
          next();
        }
        return false;
      }


      return next();
    })
  }

  // If auth is required and the user is NOT currently logged in,
  // redirect to login.
  redirectToLogin()

  function redirectToLogin() {
    // Pass the original route to the login component
    if(workspaces?.length>0) {
      next({ name: defaults.routeNotLogged[workspaces?.[0]] || 'login', query: { redirectFrom: routeTo.fullPath } })
    }
    else {
      next({ name: 'login', query: { redirectFrom: routeTo.fullPath } })
    }
  }
})

router.beforeResolve(async (routeTo, routeFrom, next) => {
  // console.log('beforeResolve', routeTo);
  // Create a `beforeResolve` hook, which fires whenever
  // `beforeRouteEnter` and `beforeRouteUpdate` would. This
  // allows us to ensure data is fetched even when params change,
  // but the resolved route does not. We put it in `meta` to
  // indicate that it's a hook we created, rather than part of
  // Vue Router (yet?).

  if(routeTo.query.isDark) {
    let isDark = JSON.parse(routeTo.query.isDark) || false;
    if(window.vueApp && window.vueApp.$fcmSetDark) {
      window.vueApp.$fcmSetDark(isDark);
    }
    else {
      console.warn('window.vueApp.$fcmSetDark NOT FOUND');
    }
    delete routeTo.query.isDark;
    return next({...routeTo});
  }


  try {
    // For each matched route...
    for (const route of routeTo.matched) {
      if (route.meta && route.meta.beforeResolve) {
        await new Promise((resolve, reject) => {
          // If a `beforeResolve` hook is defined, call it with
          // the same arguments as the `beforeEnter` hook.
          route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
            // If the user chose to redirect...
            if (args.length) {
              // If redirecting to the same route we're coming from...
              if (routeFrom.name === args[0].name) {
                // Complete the animation of the route progress bar.
                NProgress.done()
              }
              // Complete the redirect.
              next(...args)
              reject(new Error('Redirected'))
            } else {
              resolve()
            }
          });
        });
      }
    }
    // If a `beforeResolve` hook chose to redirect, just return.
  } catch (error) {
    return
  }

  // If we reach this point, continue resolving the route.
  next()
})

// When each route is finished evaluating...
router.afterEach((routeTo, routeFrom) => {
  // console.log('afterEach', routeTo);
  // Complete the animation of the route progress bar.
  NProgress.done()
})

export default router
