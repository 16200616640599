export function getDataGenres(data, keys) {
  data ||= {};
  return {
    labels: keys.map(a=>a.text),
    datasets: [
      {
        label: 'Total',
        data: keys.map(a=>data[a.value]?.total || 0),
        borderWidth: 1,
        borderColor: '#25ff00',
        backgroundColor: '#25ff00',
        borderRadius: 4,
        cubicInterpolationMode: 'monotone',
        tension: 0.4
      },
      {
        label: 'Mulheres',
        data: keys.map(a=>data[a.value]?.gender?.F || 0),
        borderWidth: 1,
        borderColor: '#f15c94',
        backgroundColor: '#f15c94',
        borderRadius: 4,
        cubicInterpolationMode: 'monotone',
        tension: 0.4
      },
      {
        label: 'Homens',
        data: keys.map(a=>data[a.value]?.gender?.M || 0),
        borderWidth: 1,
        borderColor: '#2959c0',
        backgroundColor: '#2959c0',
        borderRadius: 4,
        cubicInterpolationMode: 'monotone',
        tension: 0.4
      },
      {
        label: 'Outros',
        data: keys.map(a=>data[a.value]?.gender?.O || 0),
        borderWidth: 1,
        borderColor: '#dccc13',
        backgroundColor: '#dccc13',
        borderRadius: 4,
        cubicInterpolationMode: 'monotone',
        tension: 0.4
      },
    ]
  };
}

export function getDataAges(data, keys) {
  data ||= {};
  return {
    labels: keys.map(a=>a.text),
    datasets: [
      {
        label: 'Total',
        data: keys.map(a=>data[a.value]?.total || 0),
        borderWidth: 1,
        borderRadius: 4,
        cubicInterpolationMode: 'monotone',
        tension: 0.4
      },
      {
        label: 'Até 18 anos',
        data: keys.map(a=>data[a.value]?.age?.['0018'] || 0),
        borderWidth: 1,
        borderRadius: 4,
        cubicInterpolationMode: 'monotone',
        tension: 0.4
      },
      {
        label: 'De 18 até 30 anos',
        data: keys.map(a=>data[a.value]?.age?.['1829'] || 0),
        borderWidth: 1,
        borderRadius: 4,
        cubicInterpolationMode: 'monotone',
        tension: 0.4
      },
      {
        label: 'De 30 até 50 anos',
        data: keys.map(a=>data[a.value]?.age?.['3049'] || 0),
        borderWidth: 1,
        borderRadius: 4,
        cubicInterpolationMode: 'monotone',
        tension: 0.4
      },
      {
        label: 'Acima de 50 anos',
        data: keys.map(a=>data[a.value]?.age?.['5000'] || 0),
        borderWidth: 1,
        borderRadius: 4,
        cubicInterpolationMode: 'monotone',
        tension: 0.4
      },
      {
        label: 'Outro',
        data: keys.map(a=>data[a.value]?.age?.['0000'] || 0),
        borderWidth: 1,
        borderRadius: 4,
        cubicInterpolationMode: 'monotone',
        tension: 0.4
      },
    ]
  };
}

const mapDist = {
  1: 11,
  2: 9,
  3: 5,
  4: 2,
  5: 1.6,
  6: 1,
  7: 0.6,
  8: 0.3,
  9: 0.13,
  10: 0.09,
  11: 0.04,
  12: 0.01,
  13: 0.008,
  14: 0.005,
  15: 0.001,
  16: 0.0001,
  17: 0.0001,
  18: 0.0001,
  19: 0.0001,
  20: 0.00001,
  21: 0.000001,
  22: 0.000001,
};

export function getMarkers(list, zoom=5) {
  const out = [];
  // const dist = 6.33 - 0.83*zoom;
  // const dist = 11.2 - 1.63*zoom;
  // let dist = 10.79 - 1.61*zoom;
  // let dist = 12.28 - 1.64*zoom;
  // Regressao linear para obet bons valores paras a distância, que não é uma distância real
  // https://www.emathhelp.net/pt/calculators/probability-statistics/linear-regression-calculator/?xs=2%2C+4%2C+7&ys=9%2C+2%2C+0.5

  let dist = mapDist[zoom];



  for (const item of list) {
    const el = out.find((a) => Math.max(Math.abs(a.position.lat-item.position.lat), Math.abs(a.position.lng-item.position.lng))<dist);
    if (el) {
      el.qtd += item.qtd;
      el.items.push(item);
      el.label = el.qtd.toFixed(0);
    }
    else {
      out.push({
        qtd: item.qtd,
        label: item.qtd.toFixed(0),
        items: [item],
        position: {
          lat: item.position.lat,
          lng: item.position.lng
        }
      })
    }
  }
  // console.log('out', out);
  // console.log('dist', dist);
  // const sizes = [];
  // for (let i = 0; i < out.length; i++) {
  //   const item = out[i];
  //   for (let j = i+1; j < out.length; j++) {
  //     const a = out[j];
  //     const dist = Math.max(Math.abs(a.position.lat-item.position.lat), Math.abs(a.position.lng-item.position.lng));
  //     sizes.push(dist);
  //   }
  // }
  // console.log('sizes', sizes.sort((a, b) => b-a));
  return out;
}