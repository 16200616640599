const moment = require('moment');

Number.prototype.fcmRound = function(precision=2) {
  if(!precision) {
    precision = 2;
  }
  const factor = Math.pow(10, precision);
  return (Math.round(this * factor)/factor) ||0;
}

Number.prototype.fcmCurrency = function() {
  const factor = Math.pow(10, 2);
  return (Math.round(this * factor)/factor) ||0;
}
