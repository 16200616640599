import firebase from 'firebase/app'
let unsubscribeConfigWeb = null;
let unsubscribeInfoConnected = null;


export const state = {
  configWeb: {},
  isOnline: true,
};

export const actions = {
  init ({ commit }) {
    if(unsubscribeConfigWeb) {
      unsubscribeConfigWeb();
    }
    if(unsubscribeInfoConnected) {
      unsubscribeInfoConnected();
    }
    unsubscribeConfigWeb = firebase.firestore().collection('config').doc('web').onSnapshot((snapshot) => {
      let value = {...snapshot.data(), id: snapshot.id};
      commit('updateConfigWeb', value);
    });

    unsubscribeInfoConnected = firebase.database().ref().child('.info/connected').on('value', (connectedSnap) => {
      commit('updateIsOnline', connectedSnap.val() === true);
    });
  },

};

export const mutations = {
  updateConfigWeb (state, newValue) {
    state.configWeb = newValue;
  },
  updateIsOnline (state, newValue) {
    state.isOnline = newValue;
  },
};
