import { mapState, mapGetters, mapActions } from 'vuex'

export const authComputed = {
  ...mapState('auth', {
    currentUser: (state) => state.currentUser,
    currentCompany: (state) => state.currentCompany,
    currentCompanyId: (state) => state.currentCompanyId,
    isSuperAdminAccess: (state) => state.isSuperAdminAccess,
    companyId: (state) => state.companyId,
    currentWorkspace: (state) => state.currentWorkspace,
  }),
  ...mapGetters('auth', ['loggedIn']),
};


export const authMethods = mapActions('auth', ['logIn', 'logOut', 'companyRegister', 'modelCompanyUpdate', 'listCompanies',
  'onAuthStateChanged', 'getAdminMailByFakeMail', 'modelClientUpdate', 'modelAdminUpdate', 'modelCompanyUserUpdate',
  'logInSocial', 'startLogin', 'endLogin', 'logInPhone', 'logInClient', 'companyChangeOpen',
  'ongRegister', 'modelOngUpdate', 'listOngs', 'modelOngUserUpdate', 'loadCompanyOnClient', 'loadCompanyOnAdmin']);


export const timeComputed = {
  ...mapState('time', {
    nowMinuted: (state) => state.nowMinuted,
  }),
};

export const configComputed = {
  ...mapState('config', {
    configConfigWeb: (state) => state.configWeb,
    configIsOnline: (state) => state.isOnline,
  }),
};
