export class FcmEvent {
  status = 'pending'; // 'pending' || 'resolved' || 'rejected'
  promise;
  rejectedFn = null;
  resolvedFn = null;
  timeout = null;
  timeoutClear = null;
  countResolve = 0;
  count = 0;
  constructor(timeout=30000) {
    this.promise = new Promise((resolve, reject)=>{
      this.resolvedFn = resolve;
      this.rejectedFn = reject;
    });
    this.timeout = timeout;
    this.countResolve = 0;
    this.count = 0;
  }
  startTimer() {
    if (this.timeout > 0) {
      this.timeoutClear = setTimeout(() => {
        if (this.status === 'pending') {
          console.warn('FcmEvent Timeout countResolve: ' + this.countResolve + ' count: ' + this.count);
          this.status = 'rejected';
          if (this.rejectedFn) {
            this.rejectedFn();
            this.rejectedFn = null;
            this.resolvedFn = null;
          }
        }
      }, this.timeout);
    }
  }
  resolve() {
    if(this.status==='pending') {
      if(this.countResolve===0 || this.countResolve<=(++this.count)) {
        this.status = 'resolved';
        if(this.timeoutClear) {
          clearTimeout(this.timeoutClear);
        }
        if(this.resolvedFn) {
          this.resolvedFn();
          this.rejectedFn = null;
          this.resolvedFn = null;
        }
      }

    }
  }
  reject() {
    if(this.status==='pending') {
      if(this.timeoutClear) {
        clearTimeout(this.timeoutClear);
      }
      this.status = 'rejected';
      if(this.rejectedFn) {
        this.rejectedFn();
        this.rejectedFn = null;
        this.resolvedFn = null;
      }
    }
  }
  setCountResolve(count) {
    this.countResolve = count;
  }
}
