
const toExport = {};

toExport.name = {
  COMPANY: 'COMPANY',
  ADMIN: 'ADMIN',
  CLIENT: 'CLIENT',
  UNKNOWN: 'UNKNOWN',
};

toExport.ExtraWorkspace = {
  COMPANY: {
    userDatabase: ''
  },
};

toExport.defaultClaims = {
  type: {},
  rules: {},
  rulesC: {},
  info: null
}

/*

type: {
 cli: true,
 adm: true,
 comp: true
}
rules: {
  adm: true,    // admin
  edit: true,   // editor
  ane: true,  // anesthetist
  sur: true,  // surgeon
},
rulesC: {
  companyId1: {
    active: true,
    adm: true,    // admin
    edit: true,   // editor
    ane: true,  // anesthetist
    sur: true,    // surgeon
  }
  companyId2: {
    active: true,
    adm: true,    // admin
    edit: true,   // editor
    ane: true,  // anesthetist
    sur: true,    // surgeon
  }
},



 */




module.exports = toExport;
